.home-dashboard{
	.widget{
		position: relative;
		padding-top: 20px;
		padding-bottom: 20px;
		min-height: 340px;
		.visitors-num{
			color: #516271;
			font-size: 4em;
			display: block;
			i{
				font-size: .5em;
			} 
		}
		.dash-info{
			font-size: 1.4em; 
			display: block;
		}
		.link{
			position: absolute;
			bottom: 0;
			right: 20px;
			border: 1px solid #FF966C; 
			display: inline-block;
			padding: 10px 15px;
			border-radius: 4px;
			text-decoration: none;
			&:hover{
				text-decoration: none;
			}
		}
	}
}

@-webkit-keyframes infinite-rotation {
 from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-moz-keyframes infinite-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes infinite-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes infinite-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.kpi-overview,
.panel{
	.loading-layer{
		display: none;
		position: fixed;
		text-align: center;
		background-color: rgba(95,96,100, 0.7);
		width: 200px;
		height: 200px;
		z-index: 99;
		overflow: hidden;
		margin: 0 auto;
  	left: 0;
  	right: 0;
  	top : 240px;
  	border-radius: 8px;
		i{
			color: #fff;
			font-size: 8em;
			-webkit-animation: infinite-rotation 1s infinite; /* Safari 4+ */
		  -moz-animation:    infinite-rotation 1s infinite; /* Fx 5+ */
		  -o-animation:      infinite-rotation 1s infinite; /* Opera 12+ */
		  animation:         infinite-rotation 1s infinite; /* IE 10+, Fx 29+ */
		  position: absolute;
		  margin: 0 auto;
	  	left: 0;
	  	right: 0;
	  	margin-top: 40px;
		}
		p{
			color: #fff;
			position: absolute;
			margin: 0 auto;
			left: 0;
			right: 0;
			padding-bottom: 20px;
			bottom : 0;
		}
	}
	.panel-body{
		padding: 25px;
		&.blurred{
			position: relative;
			-webkit-transition: all 3s ease-in-out;
    	-moz-transition: all 3s ease-in-out;
    	-o-transition: all 3s ease-in-out;
    	-ms-transition: all 3s ease-in-out;    
    	transition: all 3s ease-in-out;
			-webkit-filter: blur(7px);
  		filter: blur(7px);
		}
		.table-responsive{
			&.blurred{
				position: relative;
				-webkit-transition: all 3s ease-in-out;
	    	-moz-transition: all 3s ease-in-out;
	    	-o-transition: all 3s ease-in-out;
	    	-ms-transition: all 3s ease-in-out;    
	    	transition: all 3s ease-in-out;
				-webkit-filter: blur(7px);
	  		filter: blur(7px);
			}
		}
		.data-block{
			display: block;
			overflow: hidden;
			border-bottom: 1px solid #dedede;
		}
	}
	.ga-data-overview{
		padding-top: 20px;
		padding-bottom: 30px;
		min-height: 130px;
		.sm-data{
			min-height: 90px;
		}
		#userPieChart{
			width: auto !important;
			margin-left: -15px;
		}
		.metric{
			font-size: 3.2em;
			color: #6089C6;
			display : block;
			margin-bottom: 2px;
			&.sm{
				color: #6089C6;
				font-size: 2.5em;

			}
		}
		h4{
			display: block;
			color: #565656;
			margin-top: 0;
			font-size: 1.2em;
			font-family: 'open_sansbold';
		}
		span{
			display: block;
			color: #666;
			margin-top: 0;
			font-size: 1.1em;
			font-family: 'open_sansbold';
		}
	}
}











