textarea.form-control{
	height: 60px;
}
.personas-modal{
	.paso-1{
		.place-holder-persona-img{
			display: block;
			max-width: 150px;
			border-radius: 50%;
			margin: 0 auto;
		}
		display: block;
		.persona-image-select{
			border-left: 1px solid #dedede;
			margin-bottom: 10px;
			.thumbnail{
				border: none;
				margin-right: 2px;
			}
			img{
				border : 3px solid #dedede;
				border-radius: 50%;
				cursor: pointer;
				max-width: 90px;
				&:hover{
					
				}
			}
		}
	}
	.paso-1,
	.paso-2,
	.paso-3{
		h3{ 
			
			.step-num {
				display: inline-block;
				color: #dedede;
				border: 1px solid #dedede;
				border-radius: 50%;
				width: 50px;
				height: 50px;
				vertical-align: middle;
				text-align: center;
			}
			padding-bottom: 15px;
		}
	}
	.paso-2{
		display: none;
	}
	.paso-3{
		display: none;
	}
	.pre-persona{
		display: none;
	}
	#button_create_person{
		display: none;
	}
	.next-persona-2,
	.next-persona-3{
		display: none;
	}
}
.modal-notes,
.modal-demographic,
.modal-story{
	.modal-body{
		.form-group{
			.fa{
				color: #516271;
				margin-right: 5px;
			}
		}
	}
}

@media (min-width: 768px){
	.personas-modal{
		.modal-dialog {
  		width: 700px;
  		margin: 30px auto;
		}
	}
	.funnel-contacts{
		&.modal-dialog{
			width: 960px;
		}
	}
}