 /* Generated by Font Squirrel (http://www.fontsquirrel.com) on July 10, 2015 */

body{

}
@font-face {
    font-family: 'open_sansbold';
    src: url('../fonts/opensans/opensans-bold-webfont.eot');
    src: url('../fonts/opensans/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans/opensans-bold-webfont.woff2') format('woff2'),
         url('../fonts/opensans/opensans-bold-webfont.woff') format('woff'),
         url('../fonts/opensans/opensans-bold-webfont.ttf') format('truetype'),
         url('../fonts/opensans/opensans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal; 
    font-style: normal;

}




@font-face {
    font-family: 'open_sanslight';
    src: url('../fonts/opensans/opensans-light-webfont.eot');
    src: url('../fonts/opensans/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans/opensans-light-webfont.woff2') format('woff2'),
         url('../fonts/opensans/opensans-light-webfont.woff') format('woff'),
         url('../fonts/opensans/opensans-light-webfont.ttf') format('truetype'),
         url('../fonts/opensans/opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: lighter;
    font-style: lighter;

}




@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/opensans/opensans-regular-webfont.eot');
    src: url('../fonts/opensans/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans/opensans-regular-webfont.woff2') format('woff2'),
         url('../fonts/opensans/opensans-regular-webfont.woff') format('woff'),
         url('../fonts/opensans/opensans-regular-webfont.ttf') format('truetype'),
         url('../fonts/opensans/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}