@btn-font-weight: 300;
@font-family-sans-serif: "open_sansregular", Helvetica, Arial, sans-serif;
@theme-base : #5F6064;
@theme-bg   : #F5F6F7; 


@import "bootstrap/alerts"; 
@import "bootstrap/bootstrap";
@import "smacss/font-awesome";
@import "smacss/typos"; 
@import "smacss/navigation";
@import "smacss/forms";
@import "smacss/persona-profile";
@import "smacss/interaction";
@import "smacss/kpis";
@import "smacss/print"; 
 
body{
	font-family: 'open_sansregular', Helvetica, Arial, sans-serif;
	background-color: @theme-bg;
}
 
body, label, .checkbox label {
	font-weight: 300; 
	-webkit-font-smoothing: antialiased; 
} 
strong{
	font-family: 'open_sansbold';
}
h2{
	&.total_contacts{
		margin-top: 0;
		font-family: 'open_sanslight';

		.number{
			color: @theme-base;
			font-family: 'open_sansbold';
			display: inline-block;
		}
	}
}
a:focus{
	outline: none;
}
.chart-prospect-percent{
	padding-top: 20px;
	padding-bottom: 20px;
	h3{
		font-family: 'open_sansbold';
		color: #565656;
		padding-bottom: 10px;

	}
	canvas{
		width: 100%;
	}
}
#funnel{
	svg{
		g{
			cursor: pointer;
		}
	}
}
text{
	font-family: 'open_sansbold';
}
.mt-line{
	margin-top: 22px;
}
.caret{
	color: @theme-base;
}
.panel{
	.search-option-top{
		margin-top: 0;
		padding-left: 0;
	}
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
	border-radius: 6px;
	border: 0;
	.panel-heading{
		overflow: hidden;
		background-color: @theme-base;
		color: #fff;
		border-top-right-radius: 6px;
    border-top-left-radius: 6px;
		h3{
			margin-top: 10px;
			color: #fff;
			font-size: 1.5em;
			font-family: 'open_sansbold';
		}
		.inputs-date{
			margin-top: 5px;
			color: #fff;
			display: inline-block;
			.calendar{
				font-size: 1.6em;
			}
			.form-control{

				display: inline-block;
				width: 150px;
				margin-left: 5px;
				margin-right: 5px;
				border: 0;
			}
			.date-submit{
				margin-top: -2px;
				font-size: 1em;
				i{
					font-size: 1em;
					margin-left: 5px;
					top: 0;
					&.rotate{
						-webkit-animation: infinite-rotation 1s infinite; /* Safari 4+ */
					  -moz-animation:    infinite-rotation 1s infinite; /* Fx 5+ */
					  -o-animation:      infinite-rotation 1s infinite; /* Opera 12+ */
					  animation:         infinite-rotation 1s infinite; /* IE 10+, Fx 29+ */
					}
				}
			}
		}
	}
	.panel-options{
		margin-bottom: 20px;
		.settings-dropdown{
			padding-left: 15px;
			.fa{
				font-size: 2.5em;
			}
			.caret{
				vertical-align: top;
				margin-top: 24px;
   	 		margin-left: 5px;
			}
		}
	}
	.panel-body{
		padding: 25px;
	}
}
.dataTables_paginate{
	.pagination{
		margin-top: 0;
		margin-bottom: 0;
		float: right;		
	}
}
.campaigns-modals-size{
	overflow-y: auto;
  min-height: 0.01%;
  max-height: 550px;
}

.panel-footer{
	overflow: hidden;
}
.alert{
	z-index: 1000;
	margin-top: 50px;
	position: fixed; 
}

.col-md-12{
	&.no-pad-sides{
		padding-left: 0;
		padding-right: 0;
	}
}

.etapa-ul{
	list-style: none;
	padding-left: 0;
	.li-etapa{
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 10px;
		padding-bottom: 10px;
		overflow: hidden;
		border-bottom: 1px solid #dedede;
		.li-label{
			margin-top: 6px;
			display: inline-block;
		}
		.pull-right{
			border-radius: 5px;
			cursor: pointer;
			padding: 5px 8px;
			strong{
				color: #fff;
			}
		}
		&.li-calificar{
			.pull-right{
				background-color: #5E97F6;
				&:hover{
					background-color: darken(#5E97F6, 10%)
				}
			}
		}
		&.li-no-calificar{
			.pull-right{
				background-color: #FF5A5E;
				&:hover{
					background-color: darken(#FF5A5E, 10%)
				}
			}
		}
		&.li-prospectar{
			.pull-right{
				background-color: #FAA400;
				&:hover{
					background-color: darken(#FAA400, 10%)
				}
			}
		}
		&.li-webinar{
			.pull-right{
				background-color: #06A862;
				&:hover{
					background-color: darken(#06A862, 10%)
				}
			}
		}
	}
}

.chart-legend{
	.pie-legend{
		list-style: none;
	}
	span{
		width: 10px;
		height: 10px;
		display: inline-block;
		border-radius: 50%;
		margin-right: 5px;
	}
}

#chart_div{
	rect{
		cursor: pointer;
	}
}

.thumbnail{
	&.panel-contact{
		border: 0px;
		img{
			margin-bottom: 10px;
			display: block;
			max-width: 180px !important;
			max-height: 180px;
			border-radius: 50%;
		}
	}
}


table{
	&.calificar-class{
		thead{
			tr{
				th{
					color: rgb(94, 151, 246);
					border-bottom: 2px solid rgb(94, 151, 246);
				}
			}
		}
		tbody{
			tr{

			}
			td{
				.btn-default{
					border-color: rgb(94, 151, 246);
					i{
						color :rgb(94, 151, 246);
					}
				}
			}
		}
	}
	&.no-calificar-class{
		thead{
			tr{
				th{
					color: #FF5A5E;
					border-bottom: 2px solid #FF5A5E;
				}
			}
		}
		tbody{
			tr{

			}
			td{
				.btn-default{
					border-color: #FF5A5E;
					i{
						color :#FF5A5E;
					}
				}
			}
		}
	}
	&.prospectar-class{
		thead{
			tr{
				th{
					color: #FAA400;
					border-bottom: 2px solid #FAA400;
				}
			}
		}
		tbody{
			tr{

			}
			td{
				.btn-default{
					border-color: #FAA400;
					i{
						color :#FAA400;
					}
				}
			}
		}
	}
	&.webinar-class{
		thead{
			tr{
				th{
					color: #06A862;
					border-bottom: 2px solid ;
				}
			}
		}
		tbody{
			tr{

			}
			td{
				.btn-default{
					border-color: #06A862;
					i{
						color :#06A862;
					}
				}
			}
		}
	}
	thead{
		tr{
			th{
				&.thead-stage{
					border-bottom: 0;
					font-size: 1.2em;
					
					&.th-suscriptores{
						color: #09b0d6;
					}
					&.th-leads{
						color: #FC6A6A;
					}
					&.th-oportunidades{
						color: #59c177;
					}
					&.th-clientes{
						color: #5d6aa6;
					}
					&.th-promotores{

					}
				}
			}
		}
	}
}

    
