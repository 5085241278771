.interactions{
	display: block;
	.interaction{
		display          : block;
		border           : 1px solid #E0E3E8;
		padding          : 20px 20px;
		border-radius    : 5px;
		margin-bottom    : 20px;
		background-color : #F3F5F6;
		box-shadow       : 0 1px 5px rgba(0, 0, 0, 0.15);
		.oportunity-stage{
			padding-bottom: 20px;
			padding-top: 10px;
			font-family: 'open_sansbold';
			.fa{
				font-size: 2.5em;
				margin-right: 10px;
				&.calificated{
					color: @brand-warning;
				}
				&.implemented{
					color: @brand-info;
				}
				&.no-calificado{
					color: @brand-danger;
				}
				&.prospect{
					color: @brand-success;
				}
				&.vender{
					color: @brand-warning;
				}
				&.webinar{
					color: @brand-primary;
				}
			}
		}
	}
}

@media (min-width: 768px){
	.modal{
		&.contact-campaign,
		&.blog-campaign{
			.modal-dialog {
    		width: 960px !important;
   			margin: 30px auto;
			}
		}
	}
}
