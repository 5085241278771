.persona-profile{
	padding-top: 50px;
	.row{
		.bg-persona{ 
			padding-top: 20px;
			padding-bottom: 20px;
			position: relative;
			background-attachment: fixed;
			background-position: 50% 0;
			.panel-heading{
				padding-top: 20px;
				padding-bottom: 20px;
				background-image: url('../images/bg-persona.png');
				background-repeat: no-repeat;
				background-size: cover;
			}
			.profile-pic{
				text-align: center;
				img{
					display: block;
					border-radius: 50%;
					max-width: 120px;
					height: 120px;
					width: 100%;
					overflow: hidden;
					margin: 0 auto;
					border : 4px solid #FF966C;
					box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
				}
			}
		}
		.name-role{
			padding-top: 0px;
			h2{
				font-size: 2em;
				padding-bottom: 0;
				margin-bottom: 0;
				font-family: 'open_sansbold';
				color: #fff;
			}
			h3{
				margin-top: 8px;
			}
		}
	}
}
.persona-info{
	padding-top: 20px;
	padding-bottom: 50px;
	.info-block{
		h2{
			font-family: 'open_sanslight';
			padding-bottom: 8px;
			margin-bottom: 20px;
			font-size: 2em;
			&.story{
				display: block;
    		line-height: 1.7;
    		padding-bottom: 20px;
    		padding-top: 20px;
			}
			.fa{
				color: #516271;
			}
			.i-orange{
				color: #FF966C;
				margin-left: 20px;
				margin-top: 2px;
				cursor: pointer;
			}
		}
		.subtitle-info{
			font-size: 1.2em;
		}
		span{
			font-size: 1em;
			display: block;
			font-family: 'open_sansbold';
			.fa{
				color: @theme-base;
			}
			&.info{
				font-family: 'open_sansregular';
				color: #727985;
				margin-top: 15px;
				margin-bottom: 15px;
				padding-bottom: 15px;
				font-size: 1.1em;
			}
			&.paragraph{
				display: block;
    		line-height: 1.8;
    		margin: 0 auto;
    		border-bottom: 0;
    		padding-bottom: 50px;
			}
		}
		&.actions{
			overflow: hidden;
			.btn{
				i{
					font-size: 1.3em;
				}
			}
		}
	}
}
.person-actions{
	.btn{
		margin: 0 auto;
		margin-bottom: 10px;
		display: block;
		max-width: 200px;
	}
}

@media print {
	[class*="col-md-"] {
    float: left;
  }
  .story{
  	padding-top: 68px;
  }
	.persona-profile{
		padding-top: 50px;
		.row{
			.bg-persona{ 
				padding-top: 20px;
				padding-bottom: 20px;
				position: relative;
				background-attachment: fixed;
				background-position: 50% 0;
				.panel-heading{
					padding-top: 10px;
					padding-bottom: 10px;
					background-image: url('../images/bg-persona.png');
					background-repeat: no-repeat;
					background-size: cover;
				}
				.profile-pic{
					text-align: center;
					img{
						display: block;
						border-radius: 50%;
						max-width: 120px;
						height: 120px;
						width: 100%;
						overflow: hidden;
						margin: 0 auto;
						border : 4px solid #FF966C;
						box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
					}
				}
			}
			.name-role{
				padding-top: 0px;
				h2{
					font-size: 1.5em;
					padding-bottom: 0;
					margin-bottom: 0;
					font-family: 'open_sansbold';
					color: #fff;
				}
				h3{
					margin-top: 3px;
				}
			}
		}
	}
	.persona-info{
		padding-top: 20px;
		padding-bottom: 50px;
		.info-block{
			h2{
				font-family: 'open_sanslight';
				padding-bottom: 8px;
				margin-bottom: 20px;
				font-size: 2em;
				&.story{
					display: block;
	    		line-height: 1.7;
	    		padding-bottom: 20px;
	    		padding-top: 20px;
				}
				.fa{
					color: #516271;
				}
				.i-orange{
					color: #FF966C;
					margin-left: 20px;
					margin-top: 2px;
					cursor: pointer;
				}
			}
			.subtitle-info{
				font-size: 1.2em;
			}
			span{
				font-size: 1em;
				display: block;
				font-family: 'open_sansbold';
				.fa{
					color: @theme-base;
				}
				&.info{
					font-family: 'open_sansregular';
					color: #727985;
					margin-top: 15px;
					margin-bottom: 15px;
					padding-bottom: 15px;
					font-size: 1.1em;
				}
				&.paragraph{
					display: block;
	    		line-height: 1.8;
	    		margin: 0 auto;
	    		border-bottom: 0;
	    		padding-bottom: 50px;
				}
			}
			&.actions{
				overflow: hidden;
				.btn{
					i{
						font-size: 1.3em;
					}
				}
			}
		}
	}
	.person-actions{
		.btn{
			margin: 0 auto;
			margin-bottom: 10px;
			display: block;
			max-width: 200px;
		}
	}
}