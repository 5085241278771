
.navbar-default {
	border: none !important;
	box-shadow: 0px 2px 3px rgba(0,0,0,0.1);
}

.navbar-text{
	margin-top: 9px;
	margin-bottom: 0;
	.btn {
		border: none;
		span{
			color: @theme-base;
			&.fa-bell{
				color: #F9D423;
			}
			&.fa-calendar{
				color: #D95B43;
			}
		}
	}  
} 
.navbar-nav{
	.dropdown{
		a{
			i{
				color: #FF966C;
			}
		}
	}
}

.dropdown-menu {
	min-width: 190px;
	li{
		a{
			i{
				display: block;
				margin-left: 8px;
				float: right;
				vertical-align: middle;
				margin-top: 3px;
				color: @theme-base;  
			}
		}
	}
}

.container {
	padding-top: 80px;
	padding-bottom: 50px;
	.row {
		h2{
			padding-bottom: 20px;
			i{
				color: #FF966C;
			}
		}
		.pagination {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}