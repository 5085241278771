@media print {
	#persona-pic{
		width: 100px;
	}
	.bg-persona{
		vertical-align: top;
		.profile-pic{
			vertical-align: top;
			display: inline-block;
		}
		.name-role{
			vertical-align: top;
			display: inline-block;
			h2{
				margin-top: 0;
			}
		}
	}
}

